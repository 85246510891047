import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import Helmet from 'react-helmet';
// import { graphql } from 'gatsby';
import {
  Label,
  Button
} from 'mw-style-react';
import DateUtils from '../../utils/dateUtils';
import Layout from '../../components/Layout/index';
import { Link, withIntl } from '../../i18n/index';
import './Post.scss';
import iconArrow from '../../images/arrow.svg';
import Utils from '../../utils/utils';
import PostItem from '../../components/PostItem/PostItem';
import Image from '../../components/Image/Image';

class Post extends React.Component {
  componentWillMount() {
    if (typeof window !== 'undefined') {
      window.__sharethis__ = null;
      Utils.addTag('script', {innerText: `
        window.shar = (function(d, s, id) {
          var js, fjs = d.getElementsByTagName(s)[0],
            t = {};
          js = d.createElement(s);
          js.id = id;
          js.src = '//platform-api.sharethis.com/js/sharethis.js#property=5bb1e6f67c574000111ca540&product=inline-share-buttons';
          fjs.parentNode.insertBefore(js, fjs);
          t._e = [];
          t.ready = function(f) {
            console.log('ready', f)
          };
          return t;
        }(document, "script", "shar"));
      `});
    }
  }

  renderPosts(posts, type, activePostDate, intl) {
    const copyPosts = posts.slice();
    // удаление из списка постов котороая отображается
    copyPosts.splice(copyPosts.findIndex(post => post.node.frontmatter.date === activePostDate), 1);
    // количество отображаемых статей
    const maxPostsList = 9;
    // отображаем только поервые n-постов
    const renderPosts = copyPosts.splice(0, maxPostsList);

    posts = renderPosts.map((p, index) => {
      const { date, title, url} = p.node.frontmatter;

      return (
        <div key={index}>
          <Link
            to={`/${type}/${url}`}
            className="post__item"
          >
            <Label
              value={title}
              className="post__item__title"
            />
            <Label
              value={DateUtils.toDate((new Date(date) / 1000), 'DD M YYYY', intl.locale)}
              className="post__item__date"
            />
          </Link>
        </div>
      );
    });
    return posts;
  }

  render() {
    const { intl, pageContext } = this.props;
    const { posts, post, type } = pageContext;
    const { html, fields, frontmatter } = post.node;
    const { date, title, image, description, tags } = frontmatter;

    const renderPost = posts.filter(post => post.node.frontmatter.lang === intl.locale);
    const favoritePost =
      renderPost.filter(post => post.node.frontmatter.url === this.props.pageContext.next)[0];

    const descr = description.substring(0, 70) || '';
    const isABC = fields.slug.indexOf('middleware-offers-apple-business-chat') !== -1;
    const isFranceLocale = fields.slug.indexOf('operation-defragmentation-fr') !== -1;
    const activeLocale = isFranceLocale ? 'fr' : intl.locale;

    return (
      <Layout page={type}>
        <Helmet>
          <meta property="og:title" content={title} />
          <meta property="og:description" content={descr} />
          <meta property="og:image" content={`https://corezoid.com${image}`} />
          <title>
            {title}
          </title>
          <meta
            name="description"
            content={descr}
          />
        </Helmet>
        <div className="post__header blog__header__top"/>
        <div className="grid-wrapper post">
          <div className="col-9">
            <div
              className="post__title"
            >
              <h1>{title}</h1>
            </div>
            <div className="post__info">
              <Label
                value={DateUtils.toDate((new Date(date) / 1000), 'DD M YYYY', activeLocale)}
                className="post__info__date"
              />
              <div className="sharethis-inline-share-buttons"/>
            </div>
            <div dangerouslySetInnerHTML={{ __html: html }} />
            <div className="post__footer">
              <div className="post__footer__tags" onClick={(e) => e.stopPropagation()}>
                {tags.map(tag => tag && (
                  <Link className="article__tags__item" to={`/blog/${tag}`}>{`#${tag} `}</Link>
                ))}
              </div>
              <div className="post__footer__share">
                <div className="sharethis-inline-share-buttons" />
              </div>
            </div>
            {isABC ?
              <Link className="post__recommended" to={'/bot-platform/apple-messages-for-business'}>
                <div className="post__recommended__abc">
                  <div className="post__recommended__abc__icon">
                    <Image file={'external-link.svg'}/>
                  </div>
                  <div className="post__recommended__abc__image">
                    <Image file={'abc.svg'}/>
                  </div>
                  <div className="post__recommended__abc__text">
                    <span className="post__recommended__abc__title">{intl.formatMessage({id: 'abcSubTitle'})}</span>
                    <span>{intl.formatMessage({id: 'abcText'})}</span>
                  </div>
                </div>
              </Link> :
              <div>
                <Label
                  value={intl.formatMessage({id: 'blogRecommendedArticle'})}
                  className="post__recommended"
                />
                {favoritePost &&
                  <PostItem
                    data={favoritePost.node}
                    type="post"
                  />
                }
              </div>
            }
          </div>
          <div className="col-3 post__rightList">
            {this.renderPosts(renderPost, type, date, intl)}
            <Link
              to={`/${type}`}
              className="post__all"
            >
              <Label
                value={intl.formatMessage({id: 'blogViewAll'})}
                className="post__all__text"
              />
              <img src={iconArrow} alt="More"/>
            </Link>
          </div>
        </div>
        <div className="post__demo">
          <div className="post__demo__wrapper">
            <div className="post__demo__title">
              {intl.formatMessage({id: 'postDemoTitle'})}
            </div>
            <div className="post__demo__buttons">
              <Link to={intl.formatMessage({id: 'postDemoBtnCreateLink'})}>
                <Button
                  className="button__default post__demo__btn"
                  label={intl.formatMessage({id: 'postDemoBtnCreate'})}
                />
              </Link>
              <Link to={'/form/live-demo'}>
                <Button
                  className="button__secondary post__demo__btn"
                  label={intl.formatMessage({id: 'postDemoBtnDemo'})}
                />
              </Link>
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}

Post.propTypes = {
  pageContext: PropTypes.object,
  intl: PropTypes.object
};

export default withIntl(injectIntl(Post));
