import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Link } from '../../i18n';
import './PostItem.scss';
import DateUtils from '../../utils/dateUtils';

class PostItem extends PureComponent {
  render() {
    const { data, type, lang } = this.props;
    const { frontmatter } = data;

    if (type === 'post') {
      return (
        <div
          className="article"
          key={frontmatter.url}
        >
          <Link
            className="article__favorite"
            to={`/blog/${frontmatter.url}`}
          >
            <div className="article__favorite__imageContainer">
              <img
                className="article__favorite__image"
                src={frontmatter.image}
                alt={frontmatter.title}
              />
            </div>
            <div className="article__favorite__context" id={frontmatter.url}>
              <div className="article__favorite__title">
                {frontmatter.title}
              </div>
              <div className="article__favorite__text">
                {frontmatter.description}
              </div>
              {/* <div
                  className="article__favorite__text"
                  dangerouslySetInnerHTML={{ __html: frontmatter.description }}
                /> */}
            </div>
          </Link>
        </div>
      );
    }

    return (
      <Link
        to={`/blog/${frontmatter.url}`}
        className="article grid-wrapper"
        key={frontmatter.url}
        id={frontmatter.url}
      >
        <div className="article__imageContainer">
          <img
            className="article__image"
            src={frontmatter.image}
            alt={frontmatter.title}
          />
        </div>
        <div className="article__context">
          <h4 className="article__date">
            {DateUtils.toDate(new Date(frontmatter.date) / 1000, 'DD M YYYY', lang)}
          </h4>
          <h2 className="article__title">
            {frontmatter.title}
          </h2>
          <h3 className="article__text">
            {frontmatter.description}
          </h3>
           {/* <div
              className="article__text"
              dangerouslySetInnerHTML={{ __html: frontmatter.description }}
            /> */}
          <div className="article__tags" onClick={(e) => e.stopPropagation()}>
            {frontmatter.tags.map(tag => tag && (
              <Link className="article__tags__item" to={`/blog/${tag}`}>{`#${tag} `}</Link>
            ))}
          </div>
        </div>
      </Link>
    );
  }
}

PostItem.propTypes = {
  data: PropTypes.object,
  type: PropTypes.string,
  lang: PropTypes.string
};


export default PostItem;
